import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { LiveChatWidgetModule } from '@livechat/widget-angular';
import { ItkAppComponent } from './app.component';
import { ItkTopnavModule } from './topnav/topnav.module';
import { ItkAppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ItkHttpErrorInterceptor } from '@itk/ng-error';
import { ItkToastModule } from '@itk/ng-toast';
import { ItkBackendConfigModule } from './backend.module';
import { ItkSettingsModule } from './settings/settings.module';
import {
  ItkDjangoApi,
  ItkDjangoUnderwritingApi,
} from '@itk/universal-django-api';
import { ITK_NG_DJANGO_API_CLIENT } from '@itk/ng-django-api';
import {
  LocalStorageDirective,
  NGX_LOCAL_STORAGE_CONFIG,
} from 'ngx-localstorage';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { noop } from '@itk/util-stubs';
import {ItkCrmV2PhoneComponent} from './crm-v2/phone/phone.component';

@NgModule({
  declarations: [ItkAppComponent],
  imports: [
    BrowserAnimationsModule,
    ItkBackendConfigModule,
    LiveChatWidgetModule,
    ItkAppRoutingModule,
    ItkCrmV2PhoneComponent,
    ItkToastModule,
    ItkTopnavModule,
    ItkSettingsModule,
    LocalStorageDirective,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ItkHttpErrorInterceptor,
      multi: true,
    },
    {
      provide: ItkDjangoUnderwritingApi,
      deps: [ITK_NG_DJANGO_API_CLIENT],
      useFactory: (api: ItkDjangoApi): ItkDjangoUnderwritingApi => {
        return new ItkDjangoUnderwritingApi(api);
      },
    },
    {
      provide: NGX_LOCAL_STORAGE_CONFIG,
      useValue: { prefix: 'itk', delimiter: '-' },
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => (): void => noop(),
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [ItkAppComponent],
})
export class AppModule {}
