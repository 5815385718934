<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="vm.registered">
    <span *ngIf="vm.isDestroyed || vm.isHungup" class="success h6"
      >Accepting Calls</span
    >
    <ng-container *ngIf="call$ | async as call">
      <div *ngIf="!vm.isDestroyed && !vm.isHungup" class="flex flex-col card">
        <div class="flex flex-row items-center justify-between h-spacing-loose">
          <span *ngIf="vm.callerId">{{ vm.callerId }}</span>
          <div
            class="flex h-spacing"
            *ngIf="vm.isNew || vm.isTrying || vm.isRequesting"
          >
            <button
              *ngIf="vm.isInbound"
              class="primary circular"
              (click)="answerCall(call)"
            >
              <svg-icon key="phone-enabled" fontSize="1.5em" />
            </button>
            <button class="fail circular" (click)="rejectCall(call)">
              <svg-icon key="call-end" fontSize="1.5em" />
            </button>
          </div>
          <div class="flex h-spacing" *ngIf="vm.isActive || vm.isEarly">
            <button class="fail circular" (click)="disconnectCall(call)">
              <svg-icon key="call-end" fontSize="1.5em" />
            </button>
            <button
              *ngIf="!vm.isMuted"
              class="transparent circular"
              (click)="mute(call)"
            >
              <svg-icon key="mic" fontSize="1.5em" />
            </button>
            <button
              *ngIf="vm.isMuted"
              class="transparent circular"
              (click)="unmute(call)"
            >
              <svg-icon key="mic-off" fontSize="1.5em" />
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
