import {ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {ItkMemberService} from "../services/member.service";
import {Router} from "@angular/router";
import {BrowserEnvConfig} from "../app.config";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'itk-crm-v2-version-router',
  standalone: true,
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItkCrmV2VersionRouterComponent implements OnInit {
  constructor(
    private readonly member: ItkMemberService,
    private readonly config: BrowserEnvConfig,
    private readonly router: Router
  ) {} 

  ngOnInit(): void {
    firstValueFrom(this.member.member$).then(( { is_crm_v2_enabled }) => {
      if (is_crm_v2_enabled) {
        this.router.navigate(['/crm', 'v2', 'dashboard']);
      } else {
        window.location.href = `${this.config.vueApp}/crm`;
      }
    })
  }
}
