import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ItkIconsModule } from '@itk/icons';
import {
  Observable,
  Subject,
  combineLatest,
  filter,
  map,
  merge,
  share,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import Call from '@telnyx/webrtc/lib/src/Modules/Verto/webrtc/Call';
import {ItkCrmV2CommunicationsService, VoiceCallState} from '../services/communications.service';
import {ITK_NG_DJANGO_API_CLIENT} from '@itk/ng-django-api';
import {ItkDjangoApi} from '@itk/universal-django-api';

@Component({
  selector: 'itk-crm-phone',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  templateUrl: './phone.component.html',
  styleUrl: './phone.component.scss',
  imports: [CommonModule, ItkIconsModule],
})
export class ItkCrmV2PhoneComponent {
  constructor(
    @Inject(ITK_NG_DJANGO_API_CLIENT) private readonly django: ItkDjangoApi,
    private readonly comms: ItkCrmV2CommunicationsService
  ) {}
  private readonly status$ = this.comms.voiceCallState$;

  public readonly call$ = this.comms.voiceCall$.pipe(
    filter((x) => x.isSome()),
    map((x) => x.valueOrThrow()),
    share(),
  );

  private readonly isMutedSource = new Subject<boolean>();
  public readonly isMuted$ = merge(
    this.isMutedSource,
    this.status$.pipe(
      filter((x) => x === VoiceCallState.New),
      map(() => true),
    ),
  ).pipe(startWith(false), share());

  // NOTE: Call has a 'direction' property but it doesn't seem to be set properly.
  private readonly callDirection$: Observable<'inbound' | 'outbound'> =
    this.call$.pipe(
      withLatestFrom(this.comms.agentPhoneNumberUnwrapped$),
      map(([call, agentPhone]) =>
        call.options.callerNumber === agentPhone
          ? 'outbound'
          : 'inbound',
      ),
    );

  private readonly outgoingCallerIdNumber$ = this.call$.pipe(
    withLatestFrom(this.callDirection$),
    filter(([_, direction]) => direction === 'outbound'),
    map(([call, _]) => call),
    map((x) => x.options.remoteCallerNumber),
    share(),
  );

  private readonly incomingCallerIdNumber$ = this.call$.pipe(
    withLatestFrom(this.callDirection$),
    filter(([_, direction]) => direction === 'inbound'),
    map(([call, _]) => call),
    map((x: Call) => x.options.remoteCallerNumber),
    share(),
  );

  private readonly callerIdNumber$ = merge(
    this.outgoingCallerIdNumber$,
    this.incomingCallerIdNumber$,
  ).pipe(share());

  private readonly unknownCaller$ = this.callerIdNumber$.pipe(
    filter((x) => x === undefined),
    map(() => ({
      callerNumber: 'Unknown',
      callerId: 'Unknown',
      clientId: undefined,
    })),
  );

  private readonly caller$ = this.callerIdNumber$.pipe(
    filter((x): x is string => x !== undefined),
  );

  private readonly clientCallerInfo$ = merge(
    this.unknownCaller$,
    this.caller$.pipe(
      switchMap((x) =>
        this.django.crmv2.getClients({
          search: x
        }).pipe(
          tap((x) => x.tapFail((e) => console.error(e))),
          filter((x) => x.isOk()),
          map((x) => x.unwrap()),
          map((y) =>
            y.count > 0 ? y.results[0] : null
          ),
          map((y) => {
            if (y === null) {
              return {
                callerNumber: x,
                callerId: x,
                clientId: null
              }
            } else {
              return {
                callerNumber: x,
                callerId: y.name,
                clientId: y.id
              }
            }
          })
        )
      ),
      share(),
    ),
  );

  private readonly callerId$ = this.clientCallerInfo$.pipe(
    map((x) => x?.callerId ?? null),
    share(),
  );

  private readonly callerClientIdSub = this.clientCallerInfo$
    .pipe(withLatestFrom(this.comms.agentPhoneNumberUnwrapped$))
    .subscribe(([{ callerId, clientId, callerNumber }, agentNumber]) => {
      if (clientId) {
        //this.log.info(
        //  {
        //    callerId,
        //    clientId,
        //    callerNumber,
        //    agentNumber,
        //  },
        //  'Logging call',
        //);
        //this.comms.sendCallLog(
        //  clientId,
        //  agentNumber,
        //  callerNumber,
        //  'incoming',
        //);
      }
    });

  public readonly vm$ = combineLatest({
    callerId: this.callerId$.pipe(startWith(null)),
    registered: this.comms.readyToReceiveCalls$,
    status: this.status$,
    isInbound: this.callDirection$.pipe(
      map((x) => x === 'inbound'),
      startWith(false),
    ),
    isRequesting: this.status$.pipe(
      map((x) => x === VoiceCallState.Requesting),
      startWith(false),
    ),
    isTrying: this.status$.pipe(
      map((x) => x === VoiceCallState.Trying),
      startWith(false),
    ),
    isNew: this.status$.pipe(
      map((x) => x === VoiceCallState.New),
      startWith(false),
    ),
    isEarly: this.status$.pipe(
      map((x) => x === VoiceCallState.Early),
      startWith(false),
    ),
    isActive: this.status$.pipe(
      map((x) => x === VoiceCallState.Active),
      startWith(false),
    ),
    isHungup: this.status$.pipe(
      map((x) => x === VoiceCallState.Hangup),
      startWith(false),
    ),
    isDestroyed: this.status$.pipe(
      map((x) => x === VoiceCallState.Destroy),
      startWith(false),
    ),
    isMuted: this.isMuted$,
  });

  rejectCall(call: Call): void {
    call.hangup();
  }

  disconnectCall(call: Call): void {
    call.hangup();
  }

  answerCall(call: Call): void {
    call.answer();
  }

  mute(call: Call): void {
    call.muteAudio();
    this.isMutedSource.next(true);
  }

  unmute(call: Call): void {
    call.unmuteAudio();
    this.isMutedSource.next(false);
  }
}
